import { Component } from "fq-foundation/Scripts/Component";

new Component().create("OCTotalCalculator", {
    element: '.f-form',
    field: 'input[type=number]',
    init: function () {
        var self = this;
        self.initTotalCalculator();
        self.global();
    },
    initTotalCalculator: function () {
        var self = this;
        $(window).keydown(function (event) {
            if (event.keyCode == 13) {
                event.preventDefault();
                return false;
            }
        });
        $(self.element + " " + self.field).each(function () {
            $(this).on('change', function () {
                if ($(this).val() < 0 || $(this).val() == -0) {
                    $(this).val('0');
                }
                var row = $(this).closest('.f-order-calculator__content');
                var quantityNeeded = 0;
                var casesOnHand = 0;
                var extraCasesNeeded = 0;
                var actualOrder = 0;
                row.each(function () {
                    quantityNeeded = parseInt($(this).find('input[name^="CQN_"]').val());
                    casesOnHand = parseInt($(this).find('input[name^="COH_"]').val());
                    extraCasesNeeded = parseInt($(this).find('input[name^="ECQN_"]').val());
                    actualOrder = parseInt((quantityNeeded - casesOnHand) + extraCasesNeeded);
                    if (actualOrder < 0) {
                        actualOrder = 0;
                    }
                });
                row.find('input[name^="AO_"]').val(actualOrder);
                self.initTotalSchoolSupply();
            });
            $(this).trigger('change');
        });
    },
    calculateTotal: function (name) {
        var totalValue = 0;
        $('input[name^="' + name + '"]').each(function () {
            totalValue += parseInt($(this).val());
        });
        return totalValue;
    },
    initTotalSchoolSupply: function () {
        var self = this;
        var totalCaseQuantityNeeded = self.calculateTotal('CQN_');
        var totalCaseonHand = self.calculateTotal('COH_');
        var totalextraCasesNeeded = self.calculateTotal('ECQN_');
        var totalActualOrder = self.calculateTotal('AO_');
        $('input[name^="TCQN_"]').val(totalCaseQuantityNeeded);
        $('input[name^="TCOH_"]').val(totalCaseonHand);
        $('input[name^="TEC_"]').val(totalextraCasesNeeded);
        $('input[name^="TAO_"]').val(totalActualOrder);
    },
    global: function () {
        var _hash = "!";
        var noBackPlease = function () {
            global.location.href += "#";
            global.setTimeout(function () {
                global.location.href += "!";
            }, 50);
        };
        global.onhashchange = function () {
            if (global.location.hash !== _hash) {
                global.location.hash = _hash;
            }
        };
        global.onload = function () {
            noBackPlease();
            document.body.onkeydown = function (e) {
                var elm = e.target.nodeName.toLowerCase();
                if (e.which === 8 && (elm !== 'input' && elm !== 'textarea')) {
                    e.preventDefault();
                }
                e.stopPropagation();
            };
        };
    }
});