import { Component } from "fq-foundation/Scripts/Component";

new Component().create("ProductList", {
    element: ".f-product-filter, .f-product-list",
    cards: ".f-product-list .product-item",
    item: ".product-item",
    init: function() {
        var self = this;
        self.bindEvents();
        self.processUrlData();
    },
    bindEvents: function () {
        var self = this;
        $(self.element).find('form').on('submit', function(e) {
            e.preventDefault();
            e.stopPropogation();
            return false;
        })
        if(window.location.href.indexOf("#filter/") > -1) {
            var qs = (window.location.href).split("#filter/")[1];
            var qa = qs.split("/");
            var gender = qa[0];
            var w = qa[1];
            var unit = w.indexOf('kgs') > -1 ? "kgs": "lbs";
            var weight = w.replace("kgs","").replace("lbs","");
            var data = {
                gender: gender,
                weight_val: weight,
                weight: unit
            }
            self.filterProducts.call(self, data);
        }        

        $(this.item + " .product-image").each(function () {
            if($(this).data('alternate').trim() === "") {
                $(this).attr('data-alternate', $(this).attr('src'));
            }
        })
        console.log($(this.item + " .product-image"))
        $(this.item + " .product-image").on("mouseover mouseout", function (e) {
            var orig = $(e.target).attr('src');
            $(e.target).attr('src', $(e.target).attr('data-alternate'));
            $(e.target).attr('data-alternate', orig);
        });
        $(this.element).find('form').on('change keyup', function (event) {
            var data = self.deSerializeObj($(this).serialize());
            self.filterProducts.call(self, data);
            if(event.record !== false) {
                self.updateHistory.call(self, data);
            }
        });
        window.onpopstate = function (e) {
            $(self.element).find("[name=gender]").prop("checked", false)
            $(self.element).find("[name=weight]").prop("checked", false)
            $(self.element).find("[name=weight_val]").val("");
            if(e.state) {
                var data = {
                    gender: event.state.gender,
                    weight_val: event.state.weight_val,
                    weight: event.state.weight
                }
                
                $(self.element).find("[name=gender][value="+data.gender+"]").prop("checked", true);
                $(self.element).find("[name=weight_val]").val(data.weight_val);
                $(self.element).find("[name=weight][value="+data.weight+"]").prop("checked", true);
(                self.filterProducts(data))[1];
            }
        };
    },
    filterProducts: function(data) {
        var self = this;
        
        $(self.cards).each(function () {
            var d = $(this).data('info');
            var genderFlag = false, weightFlag = false;
            if(data.gender) {
                if(Array.isArray(data.gender)) {
                    genderFlag = "unisex;"
                } else {
                    genderFlag = data.gender;
                }
            }
            genderFlag = !!data.gender?(((data.gender.indexOf("unisex") > -1) ? true : ((data.gender.indexOf(d.gender) > -1) ? true : ((d.gender === "unisex") ? true : false)))) : "unisex";
            var range = !!data.weight ? d.weight[data.weight.toLowerCase()] : "";
            weightFlag = (data["weight_val"] === "" ? true: ((Number(data["weight_val"]) >= range.min && Number(data["weight_val"]) <= range.max) ? true : false));
            if(weightFlag && genderFlag) {
                $(this).removeClass("animatehide");
            } else {
                $(this).addClass("animatehide");
            }
        });
    },
    updateHistory: function (data) {
        var genderParam = "";
        var weightval = "";
        var weightunit = "";
        
        if(Array.isArray(data.gender) || data.gender == undefined) {
            genderParam = 'unisex';
        } else {
            genderParam = data.gender;
        }

        if(data.weight === "" || data.weight == null) {
            weightunit = 'lbs';
        } else {
            weightunit = data.weight;
        }
        
        if(data["weight_val"] == "" || data["weight_val"] == null) {
            weightval = "";
            weightunit = "all";
        } else {
            weightval = data["weight_val"]
        }
        var urlFilter = ""
        if(genderParam == weightunit) {
            urlFilter = '';
        } else {
            urlFilter = genderParam+"/"+weightval + weightunit;
        }

        var urlArray = window.location.href.split("?");
        var baseUrl = (urlArray[0][urlArray[0].length - 1] === '/' ? urlArray[0].substr(0, urlArray[0].length - 1) :  urlArray[0]).split("/#filter/")[0];
        var state = baseUrl + "/#filter/" + urlFilter + (urlArray[1] !== undefined ? "?" + urlArray[1] : "");

        window.history.pushState({
            gender: genderParam,
            weight_val: weightval,
            weight: weightunit
        }, genderParam + "-" + weightval + weightunit, state);
    },
    deSerializeObj: function (serialized) {
        var d = {}, self = this;
        var a = serialized.split("&");
        for (var i = 0 ; i< a.length; i++) {
            var o = a[i].split("=");
            if(d[o[0]] == undefined) {
                d[o[0]] = o[1];
            } else {
                if(o[0] !== o[1]) {
                    var prev = d[o[0]];
                    d[o[0]] = [];
                    d[o[0]].push(prev);
                    d[o[0]].push(o[1]);
                }
            }
        }
        return d;
    },
    processUrlData: function () {
        var self = this;
        var r = new RegExp(".*\/products\/.*?\#filter\/(.*)\/(.*)","gim");
        var res = r.exec(window.location.href.split("?")[0]);
        if(!!res) {
            self.recordState = false;
            var gender = res[1];
            var unit = res[2].indexOf('kgs')> -1 ? 'kgs' : 'lbs';
            var weight = Number(res[2].replace(unit, ''));
            if(gender === "unisex") {
                $(self.element).find(":checkbox").each(function () {
                    $(this).prop('checked', true);
                });
            } else {
                $(self.element).find(":checkbox[value="+gender+"]").prop('checked', true);
            }
            $(self.element).find(":radio[value="+unit+"]").prop('checked', true);
            $(self.element).find("[name=weight_val]").val(weight);
            var event = $.Event('change');
            event.record = false;
            $(self.element).find('form').trigger(event);
        }
    }
});