import { Component } from "fq-foundation/Scripts/Component";

var Animations = new Component().create("Animations", {
    element: `  .f-hero,
                .m-section > .m-container > .columns > .column.is-full > .f-slider,
                .m-section > .m-container > .columns > .column.is-full > .f-brandvendors,
                .m-section > .m-container > .columns > .column.is-full > .f-accordion,
                .m-section > .m-container > .columns > .column.is-full > .f-accordion-nav,
                .m-section > .m-container > .columns > .column.is-full > .f-teaser,
                .m-section > .m-container > .columns > .column.is-full > .f-cta,
                .m-section > .m-container > .columns > .column.is-full > .f-product-filter,
                .m-section > .m-container > .columns > .column.is-full > .f-product-list .product-item,
                .m-section > .m-container > .columns > .column.is-full > .f-product-details,
                .m-section > .m-container > .columns > .column.is-full > .f-product-features,
                .m-section > .m-container > .columns > .column.is-full > .f-responsive-image,
                .m-section > .m-container > .columns > .column.is-full > .f-video`,
    shouldAnimate: function (element) {
        var bounding = $(element).get(0).getBoundingClientRect();
        return bounding.top < window.innerHeight;
    },
    init: function () {
        var self = this;
        $(self.element).each(function () {
            if (!$(this).hasClass("animating")) {
                $(this).addClass('animate')
                if (self.shouldAnimate($(this))) {
                    $(this).addClass('animating')
                }
            }
        });
        $(window).on('scroll', function () {
            $(self.element).each(function () {
                if (!$(this).hasClass("animating")) {
                    $(this).addClass('animate')
                    if (self.shouldAnimate($(this))) {
                        $(this).addClass('animating')
                    }
                }
            });

        });
    }
});