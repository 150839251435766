import { Component } from "fq-foundation/Scripts/Component"

new Component().create("Header", {
    element: ".f-header",
    init: function () {
        var self = this;
        try {
            document.createEvent("TouchEvent");
            // $(".sub-menu-checkbox").on('change', function () {
            //     if ($(this).prop("checked")) {
            //         $(".sub-menu-checkbox:checked + label + .sub-menu").offset({
            //             top: $(this).closest(".f-header__nav-wrap").parent().prev().height()
            //         });
            //     }
            // });
        } catch (e) {
            // 1280px
            $(self.element).find('.main-menu-item.has-submenu').on('mouseover', function () {
                if (window.innerWidth > 1279) {
                    $(this).find(".sub-menu-checkbox").prop("checked", true);
                }
            });
            $(self.element).find('.main-menu-item.has-submenu').on('mouseout', function () {
                if (window.innerWidth > 1279) {
                    $(this).find(".sub-menu-checkbox").prop("checked", false);
                }
            });
        }
    }
});