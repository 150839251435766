import { Component } from "fq-foundation/Scripts/Component";
new Component().create("Header", {
	element: ".f-header",
	init: function() {
		var self = this;
		$(".sub-menu-checkbox-label").on('click', function () {
            if(!$("#" + $(this).attr('for')).prop('checked')) {
                $('.sub-menu-checkbox').each(function () {
                    $(this).prop('checked', false);
                    $("#" + $(this).attr('for')).prop('checked', true);
                });
            }
        });
	}
});