import { Component } from "fq-foundation/Scripts/Component";

new Component().create("SizeSheet", {
	element: '.f-sizesheet',
	addChild: '.f-sizesheet__addChild',
	childrepeatControl: '#f-sizesheet__childRepeatControl',
	childrepeatBoxId: '#f-sizesheet__childRepeatBox',
	counter: '.f-sizesheet__counter',
	error: '.f-sizesheet__error',
	submit: '.f-sizesheet__submit',
	init: function () {
		var self = this;
		self.initSizeSheet();
	},
	initSizeSheet: function() {
		var self = this;
		$(self.addChild).on('click', function () {
			var child = $(self.childrepeatControl).clone();
			$(child).removeAttr('id');
			$(child).find('a').bind('click', function() {
				$(this).parent().remove();
				self.setupCounter();
			});
			$(self.childrepeatBoxId).append(child);
			self.setupCounter();
		});
		$(self.submit).on('click', function () {
			var invalid_submit = false;
			$(this).closest("form").find('select').each(function() {
				if ($(this).val() === '') {
					$(self.error).html('All fields must be completed before you submit the form.');
					invalid_submit = true;
				}
			});
			return (invalid_submit) ? false : $(this).closest("form").submit();
		});
	},
	setupCounter: function() {
		var self = this;
		$(self.counter).each(function(index) {
			$(this).html(index + 1);
		});
	}
});