import { Component } from "fq-foundation/Scripts/Component";

new Component().create("Forms-Behavior", {
	element: 'form',
	init: function () {
		var self = this;
		// $(this.el).on("change", function () {
		// 	$(this).find("input[type=submit]")
		// })
		$(this.el + "[action*=order-calculator]").on('keypress', function (e) {
			if (e.keyCode === 13) {
				e.preventDefault();
				e.stopPropagation();
			}
		});
		$(this.element).on('submit', function (ev) {
			var val = 0;
			$(this).find('input:visible').not(':input[type=button], :input[type=submit], :input[type=reset]').each(function () {
				if ($(this).val() !== undefined && $(this).val() !== "" && $(this).val() != 0) {
					val++;
				}
			});
			if (val === 0) {
				var er = document.createElement('div');
				er.classList.add("f-form__error--empty");
				er.classList.add("error");
				var section = document.createElement('div');
				section.classList.add("f-form__element--Section");
				var content = document.createElement('div');
				content.classList.add("f-form__element--Text");
				content.innerHTML = "Form cannot be empty.";
				section.appendChild(content);
				er.appendChild(content);
				$(this).append(er);
				ev.preventDefault();
				ev.stopPropagation();
				setTimeout(function () {
					$(er).animate({
						opacity: 0
					}, 700, function () {
						$(er).remove();
						$(self.element).find(".a-submit input[type='submit']").css({
							visibility: "visible"
						}).show();
					});
				}, 5000);
				return false;
			}
		})
	}
});