import { Component } from "fq-foundation/Scripts/Component";

new Component().create("Product-details", {
    element: ".f-product-details",
    buyButton: ".f-product-details__buy-button .a-button",
    vendor: ".f-product-details__vendors",
    zoomIcon: ".icon-zoom",
    init: function () {
        var self = this;
        self.bindBuyClick();
        self.zoomProductImage();
        $(self.element).find(".f-slider").on("slidechanging", function () {
            self.revertZoomProductImage($(this).find(".f-slide__wrapper"));
        });
    },
    bindBuyClick: function () {
        var self = this;
        if ($(self.vendor).children("a").length > 1) {
            $(self.buyButton).off('click').on('click', function (e) {
                e.preventDefault();
                $(this).hide();
                $(this).next().css('display', 'inline-block');
            });
        }
        else {
            $(self.buyButton).attr("target", $(self.vendor).find("a").attr("target"));
            $(self.buyButton).attr("href", $(self.vendor).find("a").attr("href"));
        }
    },
    revertZoomProductImage: function (slider) {
        var self = this;
        var zoomIcon = $(slider).closest('.f-slider').find(self.zoomIcon);
        var wrapper = $(zoomIcon).parent();
        var img = $(wrapper).parent().find(".slick-active img");
        $(zoomIcon).removeClass('icon-zoom-out');
        img.css({ 'transform-origin': '0 0', 'transform': 'scale(1)' });
        wrapper.off('touchmove mousemove');
    },
    zoomProductImage: function () {
        var self = this;
        $(self.zoomIcon).off('click').on('click', function (e) {
            e.preventDefault();
            var wrapper = $(self.zoomIcon).parent().find(".slick-active .f-slide");
            var img = $(this).parent().find(".slick-active img");
            if (!$(this).hasClass('icon-zoom-out')) {
                $(this).addClass('icon-zoom-out');
                img.css({ 'transform-origin': '100% 100%', 'transform': 'scale(2)' });
                wrapper.on('touchmove mousemove', function (ev) {
                    ev.preventDefault();
                    var event = ev.targetTouches && ev.targetTouches.length > 0 ? ev.targetTouches[0] : ev;
                    img.css(
                        {
                            'transform-origin':
                                ((event.pageX - $(this).offset().left) / $(this).width()) * 100 + '% ' +
                                ((event.pageY - $(this).offset().top) / $(this).height()) * 100 + '%'
                        }
                    );
                });
            }
            else {
                self.revertZoomProductImage($(this).parent().find(".slick-initialized"));
            }
        });
    }
});