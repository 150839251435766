import { Component } from "fq-foundation/Scripts/Component";

new Component().create("printableForm", {
	element: '.f-orderform .f-orderform__button .a-button',
	txt: '.f-orderform .f-orderform__button .f-orderform__buttonTxt',
	init: function () {
		var self = this;
		self.confirmPrint();
		window.onafterprint = function (e) {
			self.postprint(e);
		}
		// self.postprint();

	},
	confirmPrint: function () {
		var self = this;
		$(self.element).on('click', function () {
			var print = confirm($(self.txt).html());
			if (print) {
				window.print();
			}
		});
	},
	postprint: function (event) {
		var self = this;
		alert($(self.txt).html());
		window.location.href = "/customer-dashboard";
	}
});

