import { Component } from "fq-foundation/Scripts/Component";

new Component().create("ProductList", {
    element: ".f-form",
    //element: '.f-form__element--ChildCareProductList',
    field: 'input[type=number]',
    init: function () {
        var self = this;
        self.initOrderCalculator();
    },
    initOrderCalculator: function () {
        var self = this;
        $(window).keydown(function (event) {
            if (event.keyCode == 13) {
                event.preventDefault();
                return false;
            }
        });

        $(self.element + " " + self.field).each(function () {
            $(this).on('change', function () {
                if ($(this).val() <= 0) {
                    $(this).val('0');
                }
                var row = $(this).parents('.f-order-calculator__content');
                var total = 0;
                row.find("input[type=number]").each(function () {
                    if ($(this).data('unitsperday') !== undefined) {
                        var unitsPerDay = parseInt($(this).data('unitsperday'));
                        var value = $(this).val();
                        total += (value * unitsPerDay);
                    }
                });
                row.find('input[name^="USN_"]').val(total);
                if ($(this).attr('name').toLowerCase().indexOf("wipes") <= 0) {
                    self.initTotalWipesCalculator();
                }
            });
            console.log($(this))
            $(this).trigger('change');
        });
    },
    calculateTotal: function (name) {
        var totalValue = 0;
        $('input[name^="' + name + '"]:not([readonly])').each(function () {
            totalValue += parseInt($(this).val());
        });
        return totalValue;
    },
    initTotalWipesCalculator: function () {
        var self = this;
        var totalFTChildren = self.calculateTotal('FT_');
        var totalPTChildren = self.calculateTotal('PT_');
        $('input[name^="FT_Wipes"]').val(totalFTChildren);
        $('input[name^="PT_Wipes"]').val(totalPTChildren).trigger('change');
    }
});