import { Component } from "fq-foundation/Scripts/Component"
import "fq-feature/Slider/src/Scripts/main"

var slider = new Component().create("Slider", {
    element: ".f-slider",
    sliderElement: ".f-slide__wrapper",
    init: function () {
        var self = this;
        $(this.element).each(function () {
            var curEl = $(this);
            var options = JSON.parse(JSON.stringify($(this).data('slick-settings')));
            options = (options === "") ? {} : options;
            options.appendDots = undefined;
            self.setArrowAndDotsColors(options, curEl);
            if ($(this).hasClass("f-slider--hotspot")) {
                options.appendDots = $(this).find('.f-slider__image--hotspot .f-slider__image-wrapper');
                options.dots = true;
            }
            $(this).find(self.sliderElement).on('init', function (event, slick) {
                if ($(this).closest(self.element).hasClass("f-slider--hotspot")) {
                    if ($(curEl).closest(self.element).hasClass(self.element.replace('.', '') + "--hotspot")) {
                        self.setHotSpotLocations($(curEl).closest(self.element));
                        $(window).resize(function () {
                            setTimeout(function () {
                                self.setHotSpotLocations($(curEl).closest(self.element));
                            }, 100);
                        });
                    }
                } else {
                    // if($(curEl).closest(self.element).find('.f-slide > .f-quote').length > 0) {
                    $(curEl).closest(self.element).find('.f-slide').css({
                        height: "100%"
                    }).find('.columns').css({
                        height: "100%"
                    });
                    $(window).resize(function () {
                        setTimeout(function () {
                            $(curEl).closest(self.element).find('.f-slide').css({
                                height: "100%"
                            }).find('.columns').css({
                                height: "100%"
                            });
                        }, 100);
                    });
                    // }
                }

            });
            self.initialize($(this), $(this).find(self.sliderElement), options);
        });
    },
    setHotSpotLocations: function (scope, b, c) {
        var dot_positions = [];
        $(scope).find('.slick-slide:not(.slick-cloned) .f-slide').each(function () {
            dot_positions.push(typeof $(this).data('hotspot') === 'object' ? $(this).data('hotspot') : { x: 0, y: 0 })
        });
        $(scope).find('.f-slider__image--hotspot .f-slider__image-wrapper li').each(function (index, hotspot) {
            $(hotspot).css({
                top: dot_positions[index].y,
                left: dot_positions[index].x
            });
        });
    }
});